/* New branding */
:root {
    --color-red-fanta: #FF7262;
    --color-royal: #062B62;
    --color-royal-text: var(--color-white);
    --color-royal-light: #154388;
    --color-green-light: #46D1B4;
    --color-green-50: #109C97;
    --color-blue-ocean: #0172DA;
    --color-blue-ocean-origial: #20A0EC;
    --color-yellow: #F7D764;
}

:root {
  --type-multiplier: 1; /* Use this to find off-system font sizes. */
  --type-small: calc(14px * var(--type-multiplier));
  --type-interface: calc(16px * var(--type-multiplier));
  --type-base: calc(16px * var(--type-multiplier));
  --type-lead: calc(20px * var(--type-multiplier));
  --type-subtitle: calc(40px * var(--type-multiplier));
  --type-title: calc(60px * var(--type-multiplier));

  --type-serif: serif;
  --type-headline: 'General Sans', sans-serif;
  --type-sans: 'Inter Variable', sans-serif;

  --box-shadow--default: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  --border-radius--default: 2px;
  --transition--default: all 250ms ease-in-out;

  --spacing-1: 4px;
  --spacing-2: 8px;
  --spacing-3: 12px;
  --spacing-4: 16px;
  --spacing-5: 20px;
  --spacing-6: 24px;
  --spacing-7: 28px;
  --spacing-8: 32px;
  --spacing-9: 36px;
  --spacing-10: 40px;
  --spacing-11: 44px;
  --spacing-12: 48px;

  --elevation-0: 0px 0px 0px 0px rgba(0,0,0,0.2), 0px 0px 0px 0px rgba(0,0,0,0.14), 0px 0px 0px 0px rgba(0,0,0,0.12);
  --elevation-1: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  --elevation-2: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  --elevation-3: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
  --elevation-4: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  --elevation-8: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);
  --elevation-10: 0px 6px 6px -3px rgba(0,0,0,0.2), 0px 10px 14px 1px rgba(0,0,0,0.14), 0px 4px 18px 3px rgba(0,0,0,0.12);
  --elevation-20: 0px 10px 13px -6px rgba(0,0,0,0.2), 0px 20px 31px 3px rgba(0,0,0,0.14), 0px 8px 38px 7px rgba(0,0,0,0.12);


  --form-internal-padding: 0.5rem 1rem;
  --form-margin-bottom: 1rem;
  --form-border: 1px solid var(--color-medium-shade);


  --color-primary-light: #6DD9DA;
  --color-primary: var(--color-blue-ocean);
  --color-primary-rgb: 84,167,168;
  --color-primary-contrast: #ffffff;
  --color-primary-contrast-rgb: 255,255,255;
  --color-primary-shade: #306061;
  --color-primary-tint: #65b0b1;

  --color-secondary: var(--color-royal);
  --color-secondary-rgb: 46,56,74;
  --color-secondary-contrast: var(--color-royal-text);
  --color-secondary-contrast-rgb: 255,255,255;
  --color-secondary-shade: #283141;
  --color-secondary-tint: #434c5c;


  --color-tertiary: var(--color-yellow);
  --color-tertiary-rgb: 247,210,87;
  --color-tertiary-contrast: #4a4a4a;
  --color-tertiary-contrast-rgb: 0,0,0;
  --color-tertiary-shade: #d9b94d;
  --color-tertiary-tint: #f8d768;

  --color-success: #72bb2e;
  --color-success-rgb: 114,187,46;
  --color-success-contrast: #4a4a4a;
  --color-success-contrast-rgb: 255,255,255;
  --color-success-shade: #64a528;
  --color-success-tint: #80c243;

  --color-warning: #ffce00;
  --color-warning-rgb: 255,206,0;
  --color-warning-contrast: #ffffff;
  --color-warning-contrast-rgb: 255,255,255;
  --color-warning-shade: #e0b500;
  --color-warning-tint: #ffd31a;

  --color-danger: #e0695e;
  --color-danger-rgb: 245,61,61;
  --color-danger-contrast: #ffffff;
  --color-danger-contrast-rgb: 255,255,255;
  --color-danger-shade: #b75249;
  --color-danger-tint: #ec7c71;

  --color-dark: #4a4a4a;
  --color-dark-rgb: 78,78,78;
  --color-dark-contrast: #ffffff;
  --color-dark-contrast-rgb: 255,255,255;
  --color-dark-shade: #454545;
  --color-dark-tint: #606060;

  --color-medium: #989aa2;
  --color-medium-rgb: 152,154,162;
  --color-medium-contrast: #ffffff;
  --color-medium-contrast-rgb: 255,255,255;
  --color-medium-shade: #86888f;
  --color-medium-tint: #a2a4ab;

  --color-light: #f4f5f8;
  --color-light-rgb: 244,244,244;
  --color-light-contrast: #4a4a4a;
  --color-light-contrast-rgb: 0,0,0;
  --color-light-shade: #EFEFEF;
  --color-light-tint: #f5f6f9;

  --color-black: #000;
  --color-white: #fff;
}
