<template>
    <nav v-if="currentUser" class="account" :aria-label="String($t('routes.account'))" data-test-id="Account menu">
        <div class="account__user py-4 mx-4">
            <user-avatar :user="currentUser" link>
                <span class="location">{{ currentUser.shortAddress }}</span>
            </user-avatar>
        </div>

        <v-list class="nav-list">
            <slot name="before" />

            <v-list-item>
                <v-icon v-if="icons">remove_red_eye</v-icon>
                <base-button text block color="primary" :to="{ name: 'user.detail', params: { id: currentUser.id } }" data-test-id="User profile link">
                    {{ $t('nav.mySightings') }}
                </base-button>
            </v-list-item>

            <v-list-item>
                <v-icon v-if="icons">signal_cellular_alt</v-icon>
                <base-button text block color="primary" :to="{ name: 'account.sensors' }">
                    {{ $t('nav.sensors') }}
                </base-button>
            </v-list-item>

            <v-list-item>
                <v-icon v-if="icons">notifications</v-icon>
                <base-button text block color="primary" :to="{ name: 'account.notifications' }">
                    {{ $t('nav.notifications') }}
                </base-button>
            </v-list-item>

            <v-list-item>
                <v-icon v-if="icons">person</v-icon>
                <base-button text block color="primary" :to="{ name: 'account.profile' }">
                    {{ $t('nav.profile') }}
                </base-button>
            </v-list-item>

            <v-list-item>
                <v-icon v-if="icons">settings_applications</v-icon>
                <base-button text block color="primary" :to="{ name: 'account.integrations' }">
                    {{ $t('nav.integrations') }}
                </base-button>
            </v-list-item>

            <v-list-item>
                <v-icon v-if="icons">vpn_key</v-icon>
                <base-button text block color="primary" :to="{ name: 'account.login-details' }">
                    {{ $t('nav.loginDetails') }}
                </base-button>
            </v-list-item>

            <v-list-item>
                <v-icon v-if="icons" aria-hidden="true">exit_to_app</v-icon>
                <base-button text block color="primary" data-test-id="Log-out button" @click="logout">{{ $t('actions.logout') }}</base-button>
            </v-list-item>
        </v-list>
    </nav>
</template>
<script lang="ts">
import { CurrentUser } from '@/types';
import Vue from '@/vueTyped';
import UserAvatar from '@/components/UserAvatar.vue';

export default Vue.extend({
    name: 'TheAccountNavigation',
    components: {
        UserAvatar,
    },
    props: {
        icons: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        currentUser(): CurrentUser | null {
            return this.$store.state.account.currentUser;
        },
    },
    methods: {
        async logout() {
          await this.$store.dispatch('accountLogOut');
      },
    },
});
</script>
<style lang="postcss" scoped>
.account__user {
    /* background-color: var(--color-secondary); */
    /* color: var(--color-secondary-contrast); */
    border-bottom: 1px solid var(--color-dark-tint);
}
.account :deep(.avatar__name) {
    color: var(--color-primary);
}
.v-list :deep(.v-btn) {
    justify-content: left;
}
</style>
